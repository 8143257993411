import { createI18n } from 'vue-i18n';

const AVAILABLE_LOCALES = ['ar', 'de', 'en', 'es', 'fr', 'it', 'ja', 'ko', 'pt', 'ro', 'ru', 'uk', 'zh'];

async function loadLocale(i18n, locale) {
  const messages = await import(`@/locales/${locale}.json`);
  i18n.global.setLocaleMessage(locale, messages);
}

function getUserLocale() {
  let locale = navigator.language || navigator.userLanguage;
  if(locale) {
    locale = locale.split('-')[0];
  }
  return locale;
}

export async function setupI18n(locale) {
  const userLocale = getUserLocale();
  if(!AVAILABLE_LOCALES.includes(userLocale)) {
    AVAILABLE_LOCALES.push(userLocale);
  }

  if(!locale) {
    locale = userLocale
  }
  
  const i18n = createI18n({
    legacy: false,
    locale: locale,
    fallbackLocale: 'en',
    messages: {},
  });

  for(const locale of AVAILABLE_LOCALES) {
    await loadLocale(i18n, locale);
  }

  return i18n;
}