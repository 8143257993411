<template>
  <div>
    <h1>{{ $t('install') }} Ace Script</h1>
    <p>{{ $t('install_text_1') }}</p>

    <h2>Chrome, Microsoft Edge, Brave</h2>
    <p>
      {{ $t('install_from') }}
      <a href="https://chrome.google.com/webstore/detail/ace-script/mjbepbhonbojpoaenhckjocchgfiaofo" target="_blank">Chrome Web Store</a>
    </p>

    <h2>Firefox</h2>
    <p>
      {{ $t('install_from') }}
      <a href="https://addons.mozilla.org/firefox/addon/ace-script/" target="_blank">Firefox AMO</a>
    </p>

    <!--
    <h2>Microsoft Edge</h2>
    <p>
      {{ $t('coming_soon') }}
    </p>
    -->

    <h2>
      {{ $t('none_of_the_above') }}
    </h2>
    <p>
      {{ $t('browser_not_supported') }}
    </p>
  </div>
</template>