<template>
  <div>
    <h1>{{ $t('privacy_policy') }}</h1>
    <p>{{ $t('privacy_policy_page.effective_date') }}: April 27, 2019</p>
    <p>
      {{ $t('privacy_policy_page.section_1.title') }}
    </p>
    
    <p>
      {{ $t('privacy_policy_page.section_1.text_1') }}
      <ul>
        <li>
          {{ $t('privacy_policy_page.section_1.option_1') }}
        </li>
        <li>
          {{ $t('privacy_policy_page.section_1.option_2') }}
        </li>
      </ul>
    </p>

    <h3>
      {{ $t('privacy_policy_page.section_2.title') }}
    </h3>
    <p>
      {{ $t('privacy_policy_page.section_2.text_1') }}
    </p>
    <p>
      {{ $t('privacy_policy_page.section_2.text_2') }}
    </p>

    <h3>
      {{ $t('privacy_policy_page.section_3.title') }}
    </h3>
    <p>
      {{ $t('privacy_policy_page.section_3.text_1') }}
    </p>
    <p>
      {{ $t('privacy_policy_page.section_3.text_2') }}
    </p>

    <h3>
      {{ $t('privacy_policy_page.section_4.title') }}
    </h3>
    <p>
      {{ $t('privacy_policy_page.section_4.text_1') }}
    </p>

    <h3>
      {{ $t('privacy_policy_page.section_5.title') }}
    </h3>
    <p>
      {{ $t('privacy_policy_page.section_5.text_1') }}
    </p>
  </div>
</template>