<template>
  <div class="home">

    <div class="header">
      <div class="logo">
        <img src="@/assets/icon128.png" />
      </div>
      <div class="title">
        Ace Script
      </div>
      <div class="subtitle">
        {{ $t('short_description_1') }}
      </div>
      <div class="links">
        <a href="https://awe.acestream.me/" target="_blank">
          Ace Scripts Hub
        </a>
        <router-link to="/install">
          {{ $t('install') }}
        </router-link>
      </div>
    </div>

    <p>{{ $t('short_description_2')}}</p>
    
    <h3>{{ $t('features') }}</h3>
    <p>
      {{ $t('feature_text_1') }}
      <ul>
        <li>{{ $t('feature_option_1') }}</li>
        <li>{{ $t('feature_option_2') }}</li>
      </ul>
    </p>

    <h3>{{ $t('supported_browsers') }}</h3>
    <p>
      {{ $t('browser_text_1') }}
      <ul>
        <li>{{ $t('browser_option_1') }}</li>
        <li>Firefox</li>
      </ul>
    </p>

    <h3>{{ $t('contribution') }}</h3>
    <p v-html="$t('contribution_text_1')"></p>
  </div>
</template>

<style scoped>
.home {
  line-height: 1.5;
}
.header {
  text-align: center;
}
.header .logo img {
    width: 6rem;
}
.header .title {
    font-size: 2rem;
    font-weight: 700;
}
.header .subtitle {
    font-size: 1.5rem;
}

.header .links {
  margin: 2rem 0 3rem 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.header .links a {
  margin: 0 1rem;
  padding: 0.8rem;
  min-width: 8rem;
  border: 1px solid #ccc;
  border-radius: 32px;
  color: #000;
  text-decoration: none;
}
.header .links a:hover {
  background-color: #eee;
}

h3 {
  font-size: 1.375rem;
}
</style>