<script setup>
import VueCookies from 'vue-cookies';
import { useI18n } from "vue-i18n";

const i18n = useI18n({ useScope: 'global' });

async function setLocale(event) {
  const locale = event.target.value;
  VueCookies.set('locale', locale);

  console.log('current locale', i18n.locale);
  i18n.locale.value = locale;
} 
</script>

<template>
  <div class="locale-changer">
    <select @change="setLocale">
      <option
        v-for="locale in $i18n.availableLocales"
        :key="`locale-${locale}`"
        :value="locale"
        :selected="$i18n.locale === locale"
        >
        {{ locale }}
      </option>
    </select>
  </div>
</template>