import { createApp } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import { setupI18n } from '@/i18n.js';
import VueCookies from 'vue-cookies';

import App from '@/App.vue';
import Home from '@/views/HomePage.vue';
import Install from '@/views/InstallPage.vue';
import PrivacyPolicy from '@/views/PrivacyPolicyPage.vue';
import Api from '@/views/ApiPage.vue';

// router
const routes = [
  { path: '/', component: Home },
  { path: '/install', component: Install },
  { path: '/privacy-policy', component: PrivacyPolicy },
  { path: '/privacy', component: PrivacyPolicy },
  { path: '/api', component: Api },
];
const router = createRouter({
  history: createWebHistory(),
  routes,
})

// i18n
const i18n = await setupI18n(VueCookies.get('locale'));

const app = createApp(App);
app.use(i18n);
app.use(router);

// cookies
app.use(VueCookies);

app.mount('#app');
